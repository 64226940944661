<template>
  <section id="publication" class="half d-flex flex-column justify-center grey lighten-3 half">
    <v-container class="pt-16 mt-md-16">
      <h2 class="text-h3 text-sm-h2 font-weight-bold mb-8 text-center">
        Publications
      </h2>
      <v-row justify="space-around">
        <v-col v-for="(item, i) in publications" :key="i" cols="12" sm="6" :md="item.cols">
          <v-card>
            <v-img :src="item.img" contain eager />
            <v-card-title>{{ item.title }}</v-card-title>
            <v-card-subtitle>{{ item.type }}</v-card-subtitle>
            <v-card-text>{{ item.body }}</v-card-text>
            <v-card-actions>
              <v-btn v-if="item.url" depressed block color="primary" :href="item.url">{{ item.action }}</v-btn>
              <v-btn v-else depressed block color="secondary" @click="$vuetify.goTo('#contact')">{{ item.action }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 196">
      <path
        fill="#FAFAFA"
        d="M0,96l48,10.7C96,117,192,139,288,160s192,43,288,16S768,75,864,64s192,43,288,42.7c96,0.3,192-53.7,240-80 L1440,0v196h-48c-48,0-144,0-240,0s-192,0-288,0s-192,0-288,0s-192,0-288,0s-192,0-240,0H0V96z"
      />
    </svg>
  </section>
</template>
<script>
export default {
  name: "Publications",
  data() {
    return {
      publications: [
        { 
          type: 'Whitepaper', 
          title: 'Unified Asset Intelligence', 
          body: 'AI-driven software for unconventional assets helps operators fine tune completion strategies and democratizes access to accurate production forecasting.', 
          date: 'July 2024',
          url: 'https://www.dropbox.com/scl/fi/zxjru7vfspe3m58gtv0g6/AlphaX-Unified-Asset-Intelligence-White-Paper.pdf?rlkey=ct7ixrcweq23o5ni5ez5m9xzk&dl=0',
          img: '/images/uai.jpg',
          action: 'Download White Paper',
          cols: 4
        },
        { 
          type: 'Case Study', 
          title: 'Solving a Modern Problem With Modern Technology', 
          body: 'AI-driven software for unconventional assets helps operators fine tune completion strategies and democratizes access to accurate production forecasting.', 
          date: 'July 2022', 
          url: 'https://www.dropbox.com/s/kigtfw3g99h4itk/AlphaXHartArticleUS.pdf',
          img: '/images/hart.jpg',
          action: 'Download white paper',
          cols: 3
        },
      ]
    }
  }
};
</script>
